import axios from "axios";
// import apiRoute from '@/config/api.js';
const apiRoute = process.env.VUE_APP_API_URL;

import eventBus from "@/utils/eventBus";
const feather = require("feather-icons");

export default {
  props: {
    userType: {
      type: String,
      enum: ["owner", "creator"],
    },
  },

  data() {
    return {
      email: "",
      errorVisibility: false,
      error: "",
      success: false,
    };
  },

  methods: {
    sendLink: async function () {
      let response;
      const body = {
        email: this.email,
      };

      if (this.validateData()) {
        eventBus.$emit("toggle-loader", true);

        await axios
          .post(apiRoute + "/" + this.userType + "/password/link", body, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          })
          .then((resp) => (response = resp))
          .catch((err) => (response = false));

        eventBus.$emit("toggle-loader", false);

        if (!response || response.status != 200) {
          alert("Ocurrió un error");
        } else {
          this.success = true;
        }
      }
    },

    validateData: function () {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let success = true;
      const { email } = this;

      if (email == "" || !re.test(email)) {
        success = false;
        this.error = "El email no es válido.";
        this.errorVisibility = true;
      }

      return success;
    },

    resetError: function () {
      if (this.errorVisibility) {
        this.errorVisibility = false;
      }
    },
  },

  mounted() {
    feather.replace();
  },
};
