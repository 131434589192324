import Vue from 'vue';
import InlineSvg from 'vue-inline-svg';
import VueClipboard from 'vue-clipboard2';

import VueSession from 'vue-session';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import App from '@/views/app/App.vue';
import router from '@/router';
import '@/assets/static';

Vue.use(VueSession, { persist: true });
Vue.use(VueClipboard)

Vue.component('inline-svg', InlineSvg)

Vue.use(Toast, {
  position: "bottom-center"
});

Vue.config.productionTip = true;

new Vue({
  router,
  render: function (h) {
    return h(App)
  }
}).$mount('#app');
