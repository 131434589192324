import axios from "axios";
// import apiRoute from '@/config/api.js';
const apiRoute = process.env.VUE_APP_API_URL;

import eventBus from "@/utils/eventBus";
import gsap from "gsap";
const feather = require("feather-icons");

import Logo from "@/components/logo";
import Login from "@/components/home/Login/Login.vue";
import Signup from "@/components/home/Signup/Signup.vue";
import Password from "@/components/home/PasswordModal/PasswordModal.vue";

export default {
  components: {
    Logo,
    Login,
    Signup,
    Password,
  },

  data() {
    return {
      id: this.$route.params.id ? this.$route.params.id : "",
      section: "login",
      loginData: {
        email: "",
        password: "",
        passwordVisibility: false,
        error: "",
        errorVisibility: false,
      },
      signupData: {
        name: "",
        lastName: "",
        phone: "",
        email: "",
        password: "",
        passwordVisibility: false,
        error: "",
        errorVisibility: false,
      },
      passwordModalShowed: false,
      passwordModalType: "",
      success: false,
      errorTimeout: 5000,
    };
  },

  methods: {
    changeSection: function (section) {
      const $self = this;
      let $next, $current;

      if (section == "login") {
        $next = this.$refs.login;
        $current = this.$refs.signup;
      } else {
        $next = this.$refs.signup;
        $current = this.$refs.login;
      }

      const height = $next.clientHeight;
      this.$refs["form-container"].style.height = height + "px";

      gsap.set($current, { zIndex: 1, position: "absolute" });
      gsap.to($current, 0.35, { opacity: 0 });
      gsap.to($next, 0.35, {
        left: 0,
        opacity: 1,
        onComplete: function () {
          gsap.set($next, { position: "relative" });
          $self.$refs["form-container"].style.height = "auto";
          gsap.set($current, {
            left: "100%",
            zIndex: 3,
            position: "absolute",
          });
        },
      });
    },

    signup: async function () {
      if (this.validateData("signup")) {
        let response = null;
        const $this = this;

        eventBus.$emit("toggle-loader", true);

        await axios
          .post(apiRoute + "/creator", this.signupData, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          })
          .then((resp) => (response = resp))
          .catch((err) => (response = false));

        eventBus.$emit("toggle-loader", false);

        if (!response || response.status == 500) {
          this.signupData.error = "Ocurrió un error en la consulta.";
          this.signupData.errorVisibility = true;
        } else {
          if (response.status == 203) {
            this.signupData.error = "Email utilizado previamente.";
            this.signupData.errorVisibility = true;
          } else if (response.status == 200) {
            this.success = true;
            this.$toast.success(
              "Cuenta creada con éxito. Revisa tu bandeja y verifica tu correo antes de iniciar sesión.",
              { timeout: 15000 }
            );
            this.changeSection("login");
          }
        }

        if (this.signupData.errorVisibility) {
          setTimeout(function () {
            $this.resetError("login");
          }, this.errorTimeout);
        }
      }
    },

    login: async function () {
      if (this.validateData("login")) {
        const $self = this;
        let response = null;

        eventBus.$emit("toggle-loader", true);

        await axios
          .post(apiRoute + "/creator/login", this.loginData, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          })
          .then((resp) => (response = resp))
          .catch((err) => (response = false));

        eventBus.$emit("toggle-loader", false);

        if (!response || response.status == 500) {
          this.loginData.error = "Ocurrió un error en la consulta.";
          this.loginData.errorVisibility = true;
        } else {
          if (response.status == 204) {
            this.loginData.error = "Email o contraseña incorrectos.";
            this.loginData.errorVisibility = true;
          } else if (response.status == 200) {
            this.startSession(response.data);
          }
        }

        if (this.loginData.errorVisibility) {
          setTimeout(function () {
            $self.resetError("login");
          }, this.errorTimeout);
        }
      }
    },

    validateData: function (section) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const data = section == "login" ? this.loginData : this.signupData;
      let success = true;

      if (data.email == "" || data.password == "") {
        success = false;
        this.$data[section + "Data"].error =
          "Por favor, completa todos los campos.";
      } else if (
        section == "signup" &&
        (data.name == "" || data.lastName == "")
      ) {
        success = false;
        this.$data[section + "Data"].error =
          "Por favor, completa todos los campos.";
      } else if (!re.test(data.email)) {
        success = false;
        this.$data[section + "Data"].error = "El email no es válido.";
      }

      if (success && section == "signup") {
        if (data.phone.length != 10) {
          success = false;
          this.$data[section + "Data"].error =
            "El teléfono debe contener 10 dígitos";
        } else if (data.password.length < 6) {
          success = false;
          this.$data[section + "Data"].error =
            "La contraseña debe contener por lo menos 6 caracteres";
        }
      }

      if (!success) {
        this.$data[section + "Data"].errorVisibility = true;
      }

      return success;
    },

    startSession(user) {
      this.$session.start();
      this.$session.set("id", user._id);
      this.$session.set("name", user.name);
      this.$session.set("email", user.email);
      this.$session.set("token-jwt", user.token);
      this.$router.push("/pay/" + this.id);
    },

    resetError: function (section) {
      this.$data[section + "Data"].errorVisibility = false;
    },

    togglePasswordModal: function (status, type) {
      this.passwordModalShowed = status;
      this.passwordModalType = type;
    },
  },

  mounted() {
    feather.replace();
  },

  beforeMount() {
    if (this.$session.exists() && this.$session.get("id")) {
      this.$router.push("/pay/" + this.id);
    }
  },
};
