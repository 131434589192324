import Vue from 'vue';
import Router from 'vue-router';

import Home from '@/views/home/Home.vue';
import Pay from '@/views/pay/Pay.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Home
    },
    {
      path: '/pay',
      component: Pay
    },
    {
      path: '/pay/:id',
      component: Pay
    },
    {
      path: '/:id',
      component: Home
    }
  ]
});
