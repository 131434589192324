import axios from "axios";
import moment from "moment";
// import apiDomain from "@/config/api.js";
const apiDomain = process.env.VUE_APP_API_URL;

// Utils
import eventBus from "@/utils/eventBus";
import verifySession from "@/utils/verifySession";
import extComponent from "@/utils/ext-component.js";

// Components
import Logo from "@/components/logo";
const Checkout = () => extComponent("checkout");

const feather = require("feather-icons");

export default {
  components: {
    Logo,
    Checkout,
  },

  data() {
    return {
      id: this.$route.params.id ? this.$route.params.id : "",
      language: "es",
      loaded: false,
      websiteUrl: process.env.VUE_APP_WEBSITE_URL,

      // Production enviroment
      // token:
      //   "U2FsdGVkX1+EScx76XN3n4iGjPuD7knxeePfLQikQ00ArL0gg9onaL2nt9Ob7kdlVFcz6MjmgoIEKNCxjzJhG2zImxGDwBBjXK5MmGaotF4G3Qh3dnESO/if9gEBu6Nwfp1g6Jt9n+0IRQLstqgCm4j9KzVw9HDSC41sHj3+42twURZrHYZuP/W62y6wxKNsQ2XKPK91YLYwURScRy77qA==",
      // publishableKey:
      //   "pk_live_51JyqOhAOhfqGotz2t3z0D5CefO1dLa2i6QinGtldoLvBHjsXuL3zRlEBgfkyiuYJAdE5p87s9tilVw3vNxijUKfg00i2M6y6Z0",

      // Develop enviroment
      token: process.env.VUE_APP_TOKEN,
      publishableKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,

      isValid: true,
      invalid: {},
      to: null,
      order: null,
      subtotal: 0,
      status: {
        color: null,
        title: null,
      },
      details: [],
      exp: null,
      termsLink: "/",
      policyLink: "/",
      phone: "3335938504",
      formVisibility: true,
      noForm: {},
    };
  },

  methods: {
    getReservation: async function () {
      let response;
      const { id } = this;

      await axios
        .get(apiDomain + "/reservation/" + id)
        .then((res) => (response = res))
        .catch((err) => (response = false));

      this.loaded = true;

      if (!response || response.status != 200) {
        this.isValid = false;
        this.invalid = {
          title: "Orden de pago inexistente",
          description: `Ha ocurrido un error con tu orden de pago.<br/> Te invitamos a realizar una nueva <a class='color-primary' href='${this.websiteUrl}'>reservación o búsqueda</a>.`,
        };
      } else {
        const reservation = response.data[0];

        this.getCheckoutData(reservation);
        this.isValidReservation(reservation);
      }
    },

    isValidReservation: function (reservation) {
      let response = true;

      let dateExp = new Date(reservation.dateCheckoutSent);
      const { status } = reservation;

      // dateExp.setDate(dateExp.getDate() + 1);
      this.exp = this.getDateFormat(dateExp);

      if (status == "Enviada") {
        this.status.title = "Enviada";
        this.status.color = "info";
      } else if (status == "Vencida") {
        response = false;

        this.status.title = "Vencida";
        this.status.color = "dark";

        this.formVisibility = false;
        this.noForm = {
          title: "Orden de pago caducada",
          description: `Lo sentimos, tu orden de pago ha caducado. <br/>Por favor, <a class='color-primary' target='_blank' href='https://wa.me/${this.phone}'>comunícate con nosotros</a> para obtener una nueva orden de pago.`,
        };
      } else if (status == "Confirmada") {
        response = false;

        this.status.title = "Confirmada";
        this.status.color = "primary";

        this.formVisibility = false;
        this.noForm = {
          title: "Orden de pago confirmada",
          description: `Hola, ${this.to}.<br/> Tu orden de pago ha sido pagada previamente con éxito. <br/> Nos vemos pronto ✌️.`,
        };
      } else if (status == "Cancelada") {
        response = false;

        this.status.title = "Cancelada";
        this.status.color = "light";

        this.formVisibility = false;
        this.noForm = {
          title: "Reserva cancelada",
          description: `Lamentamos informarte que tu reserva fue cancelada. <br/>Por favor, si tienes dudas <a class='color-primary' target='_blank' href='https://wa.me/${this.phone}'>comunícate con nosotros</a>.`,
        };
      } else if (status == "Checked Out") {
        response = false;

        this.status.title = "Checked Out";
        this.status.color = "success";

        this.formVisibility = false;
        this.noForm = {
          title: "¡Gracias!",
          description: `Hola, ${this.to}.<br/> El equipo Scouter está agradecido por tu visita. <br/> Esperamos verte pronto ✌️.`,
        };
      } else if (status == "Sin Disponibilidad") {
        response = false;

        this.status.title = "Sin Disponibilidad";
        this.status.color = "danger";

        this.formVisibility = false;
        this.noForm = {
          title: "Sin disponibilidad",
          description: `Nos apena informarte que tu solicitud de reserva fue rechazada debido a la falta de disponibilidad de la locación. <br/>
          Te invitamos a realizar una <a class='color-primary' href='${this.websiteUrl}/property/${reservation.property[0].slug}'>nueva reservación con nuevas fechas.`,
        };
      } else if (status == "En Espera") {
        response = false;

        this.status.title = "En Espera";
        this.status.color = "warning";

        this.formVisibility = false;
        this.noForm = {
          title: "Solicitud en progreso",
          description: `Seguimos trabajando en la disponibilidad de tu reserva. <br/>
          Te mantendremos informado.`,
        };
      }

      return response;
    },

    getDateFormat: function (val) {
      // const date = typeof val === "string" ? new Date(val) : val;

      // const dd = String(date.getDate()).padStart(2, "0");
      // const mm = String(date.getMonth() + 1).padStart(2, "0");
      // const yyyy = date.getFullYear();

      // return dd + "/" + mm + "/" + yyyy;

      return moment(val).format("DD/MM/YYYY");
    },

    getCheckoutData: function (data) {
      this.order = data.reservationNumber;

      if (data.creator.length) {
        this.to = data.creator[0].name + " " + data.creator[0].lastName;
      } else {
        this.to = this.$session.get("name");
      }

      this.details = [
        {
          name: "Propiedad",
          value: data.property[0].title,
        },
        {
          name: "Fecha",
          value: this.getDateFormat(data.date),
        },
        {
          name: "Hora Inicio",
          value: data.startTime,
        },
        {
          name: "Hora Fin",
          value: data.endTime,
        },
      ];

      if (data.discount && parseFloat(data.discount) > 0) {
        let discount =
          (parseFloat(data.discount) / 100) * parseFloat(data.total);
        discount = discount.toFixed(2);

        this.details.push({
          name: "Subtotal",
          value: "$" + data.total,
        });

        this.details.push({
          name: "Descuento",
          value: "-$" + discount,
        });

        this.details.push({
          name: "Total",
          value: "$" + data.totalDiscount,
        });

        this.subtotal = parseFloat(data.totalDiscount);
      } else {
        this.subtotal = parseFloat(data.total);
      }

      if (data.summary) {
        const { summary } = data;

        if (summary.pricePerHour) {
          this.details.push({
            name: "Presupuesto ofertado ($" + summary.pricePerHour + " x hora)",
            value: "$" + summary.property,
          });

          this.details.push({
            name: "Tarifa scouter",
            value: "$" + summary.scouterFee,
          });
        } else {
          this.details.push({
            name: "Locación ($" + data.pricePerHour + " x hora)",
            value: "$" + summary.property,
          });
        }

        this.details.push({
          name: "Impuestos",
          value: "$" + summary.taxes,
        });

        this.details.push({
          name: "Tarifa de servicio",
          value: "$" + summary.serviceFee,
        });

        this.details.push({
          name: "Total",
          value: "$" + summary.total,
        });
      }
    },

    checkoutSuccess: async function (data) {
      let response = null;
      let body = {};

      body.chargeId = data.id;
      body.creatorId = this.$session.get("id");

      eventBus.$emit("toggle-loader", true);

      await axios
        .put(`${apiDomain}/reservation/status/confirmed/${this.id}`, body)
        .then((res) => (response = res))
        .catch((err) => (response = null));

      eventBus.$emit("toggle-loader", false);

      if (response && response.status == 200) {
        this.$toast.success("Se ha realizado el pago correctamente");
        this.status.title = "Confirmada";
        this.status.color = "primary";

        this.formVisibility = false;
        this.noForm = {
          title: "Reservación confirmada",
          description: `El pago de tu reservación se ha realizado con éxito. <br/> <a class='color-primary' href='${this.websiteUrl}/search'>Explorar locaciones</a>`,
        };
      } else {
        this.$toast.warning("Ocurrió un error en el pago");
      }
    },

    checkoutError: function (error) {
      let message = "No se ha podido realizar el pago, intente más tarde";

      try {
        message = error.response.data.message;

        console.log("error from api: ", message);
      } catch (error) {}

      this.$toast.warning(message);
    },

    logout: function () {
      this.$session.destroy();
      this.$router.push("/" + this.id);
    },
  },

  mounted() {
    if (verifySession(this)) {
      feather.replace();
      this.getReservation();
    } else {
      this.$router.push("/" + this.id);
    }
  },
};
