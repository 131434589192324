import Vue from "vue";
import axios from "axios";
import apiRoute from "@/config/api";
import jwt_decode from "jwt-decode";

// Utils
import eventBus from "@/utils/eventBus";
import verifySession from "@/utils/verifySession";

const $self = new Vue();

export default function protectedRoute() {
  // 401 mensaje de error
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      try {
        if (error.response && error.response.status === 401) {
          // $self.$router.push("/");

          if (error.response.data.message == "Token inválido") {
            $self.$session.destroy();
            location.reload();
          } else {
            $self.$toast.error(error.response.data.message);
          }
        }
      } catch (error) {}

      return error;
    }
  );

  // Refresh token
  axios.interceptors.request.use(async (config) => {
    if (verifySession($self)) {
      const token = $self.$session.get("token-jwt") || null;

      if (!token) {
        eventBus.$emit("set-creator-unlogged");
      } else if (isTokenExpired(token) && !config.headers.refreshToken) {
        const newToken = await refreshToken();

        if (newToken) {
          $self.$session.set("token-jwt", newToken);
        }
      }

      if ($self.$session.get("token-jwt")) {
        config.headers.Authorization = `Bearer ${$self.$session.get(
          "token-jwt"
        )}`;
      }
    }

    return config;
  });
}

async function refreshToken() {
  let response = false;

  const _apiRoute = process.env.VUE_APP_API_URL;

  try {
    await axios
      .post(_apiRoute + "/creator/myscouter/refresh-token", null, {
        headers: {
          Authorization: `Bearer ${$self.$session.get("token-jwt")}`,
          refreshToken: true,
        },
      })
      .then((resp) => (response = resp.data.token));
  } catch (error) {
    response = false;
  }

  return response;
}

function isTokenExpired(token) {
  try {
    const decoded = jwt_decode(token);

    return decoded.exp < Date.now() / 1000;
  } catch (error) {
    return true;
  }
}
