import Loader from '@/components/module/Loader.vue';

// Utils
import eventBus from "@/utils/eventBus";
import jwtInterceptor from '@/utils/jwt.interceptor';

export default {
  components: {
    Loader
  },

  data() {
    return {
      isLoading: false
    }
  },

  methods: {
    toggleLoader: function(status) {
      this.isLoading = status;
    }
  },

  beforeMount: function() {
    jwtInterceptor();
  },
  
  mounted: function() {
    eventBus.$on("toggle-loader", (status) => {
      this.toggleLoader(status);
    });

    eventBus.$on("set-creator-unlogged", () => {
      this.$session.destroy();
      this.$router.push("/");
    });
  },
}
